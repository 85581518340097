@font-face {
  font-family: Kenney;
  src: url("./assets/Kenney-High.ttf");
}

html,
body {
  margin: 0px;
  overflow: hidden;
  background-color: black;
  font-family: Kenney;
  box-sizing: border-box;
}

.blink_me {
  animation: blinker 2s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0.25;
  }
}

#device-title-text {
  font-size: 100px;
  text-align: center;
}

#device-calibrate-text {
  width: 100%;
  font-size: 32px;
}

#device-calibrate-button-container {
  display: flex;
  width: 100%;
  justify-content: center;
  padding: 8px;
  height: 50px;
  font-size: 20px;
  margin-bottom: 24px;
  text-align: center;
  box-sizing: border-box;
}

#device-calibrate-button {
  font-size: 32px;
  border: none;
  background: none;
  background-color: #37946c;
  font-weight: 600;
  padding: 8px;
  height: 48px;
  box-sizing: border-box;
  color: #6abe30;
  border-radius: 2px;
  font-family: Kenney;
}

#device-calibrate-container {
  z-index: 1000;
  box-sizing: border-box;
  color: black;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  font-weight: 600;
  font-size: 20px;
  background-color: #87ceeb;
}

#hole-timer {
}
